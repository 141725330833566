import { useState } from 'react';
import axios from '../axiosConfig';

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const LoginApi = {

    insertAccount: async (data, optionalData) => {
        console.log(data)
        let optionalDataValues = [];
        for (let key in optionalData) {
            optionalDataValues.push({
                optionalFieldName: optionalData[key].optionalFieldName,
                optionalFieldValue: optionalData[key].optionalFieldValue,
                isDeleted: false,
                isActive: Boolean(true)
            });
        }
        try {

            const apiUrl = `${WebApiUrl}/api/Account/Account`;
            const response = await axios.post(apiUrl, 
                {
                namePrefix: data.namePrefix,
                name: data.name,
                printName: data.printName,
                alias: data.alias,
                accountType: Number(data.accountType),
                parentGrp: Number(data.parentGrp),
                aState: Number(data.aState),
                aStation: Number(data.aStation),
                aDefaultSalePerson: Number(data.aDefaultSalePerson),
                adob: data.adob, // "2024-01-31",
                aAge: Number(data.aAge),
                adom: data.adom,  //"2024-01-31",
                aGender: data.aGender,
                aDepartment: Number(data.aDepartment),
                aDesignation: Number(data.aDesignation),
                aDrugLicenseNo: data.aDrugLicenseNo,
                aOpeningBal: Number(data.aOpeningBal),
                aOpeningCrDr: data.aOpeningCrDr,               //number but pass value as string;
                aContactperson: data.aContactperson,
                contactpersonMbl: data.contactpersonMbl,
                aCreditlimit: Number(data.aCreditlimit),
                aCreditperiod: Number(data.aCreditperiod),
                aAccountCategory: Number(data.aAccountCategory),
                aEnableLoyalty: Boolean(data.aEnableLoyalty),
                aLoyaltyCardNo: data.aLoyaltyCardNo,
                referralBy: Number(data.referralBy),
                assignUser: Number(data.assignUser),
                aAddress: data.aAddress,
                aEnableEmail: data.aEnableEmail,
                aEmail: data.aEmail,
                aMobileNo: data.aMobileNo,
                aPincode: data.aPincode,
                aLocation: data.aLocation,
                aDistance: data.aDistance,
                accountDealerType: data.accountDealerType,
                agStINNo: data.agStINNo,
                aitPan: data.aitPan,
                alstNo: data.alstNo,
                acstNo: data.acstNo,
                aAadharNo: data.aAadharNo,
                doNotDisturb: data.doNotDisturb,
                aDefaultTranspoter: Number(data.aDefaultTranspoter),
                openingCrDr: data.openingCrDr,
                system_defined: Boolean(data.system_defined),
                createdBy: data.createdBy,
                createdOn: "2024-02-01", //data.createdOn,
                isActive: Boolean(data.isActive),   
                accOptFieldValues: optionalDataValues,
                password: data.password,
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data;

        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Login ***************************

    
    LogInAccount: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/Account/LogIn?MobileNo=${data.name_email}&Password=${data.password}`;

            const response = await axios.get(apiUrl, {},{
                withCredentials: true,
                credentials: 'include',
            });

           console.log("Response:", response.data);
            return response.data;

        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                //alert("Failed");
            } else {
                //alert("An error occurred. Please try again.");
            }
        }
    },


    LogInAccountOT: async (data , orderTakerID)  => {
        // debugger
        try {
            const apiUrl = `${WebApiUrl}/api/Account/LogInOT?MobileNo=${data.name_email}&OrderTakerId=${orderTakerID}`;
            const response = await axios.get(apiUrl, {},{       
                withCredentials: true,
                credentials: 'include',
            });

           console.log("Response:", response.data);
            return response.data;

        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                //alert("Failed");
            } else {
                //alert("An error occurred. Please try again.");
            }
        }
    },



    AutoLogInAccount: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/Account/LogIn?MobileNo=${data.aMobileNo}&Password=${data.password}`;

            const response = await axios.get(apiUrl, {},{
                withCredentials: true,
                credentials: 'include',
            });

           console.log("Response:", response.data);
            return response.data;

        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },

    // ******************* Check Login for order taker ************************

    LogInForOrderTaker: async (data) => {
        console.log(data)
        try {
           

            const apiUrl = `${WebApiUrl}/api/UserInfo/login`;
            console.log("Response:", apiUrl);
            const response = await axios.post(apiUrl, data, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return response.data;

        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },



};

export default LoginApi;
