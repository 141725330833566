import React, { useContext, useEffect, useState } from "react";
import DeliveryAndTimeSlot from "../API/API_SERVISES/DeliveryAndTimeSlot";
import MiscellaneousMaster from "../API/API_SERVISES/MiscellaneousMaster";
import MyContext from "./MyContext";
import CurrentDateAndTime from "../API/API_SERVISES/CurrentDateAndTime";


const MainModalComponent = (props) => {
  const { selectedService, CompanyDetail } = useContext(MyContext)

  const padWithZero = (num) => num.toString().padStart(2, '0');

  const getNext5Days = async () => {
    const startDateString = await CurrentDateAndTime.getCurrentDate();

    const startDate = new Date(startDateString);
    // alert(startDate)

    // Ensure the start date is valid
    if (isNaN(startDate)) {
      throw new Error('Invalid start date');
    }

    const nextFiveDates = [];
    const data = props.serviceType.filter(item => item.id == selectedService);

    for (let i = 0; i < data[0].advanceOrderDays; i++) {
      const date = new Date(startDate);
      date.setDate(startDate.getDate() + i);

      const formattedDate = `${padWithZero(date.getDate())}-${padWithZero(date.getMonth() + 1)}-${date.getFullYear()}`;
      nextFiveDates.push(formattedDate);
    }

    return nextFiveDates;
  };

  const [next5Days, setNext5Days] = useState([]);

  useEffect(() => {
    const fetchNext5Days = async () => {
      const dates = await getNext5Days();
      setNext5Days(dates);
    };

    fetchNext5Days();
  }, [selectedService]);

  const [Distance, setDistance] = useState(4);
  const [TimeSlot, setTimeslot] = useState([]);
  const [TimeSlotAccToKM, setTimeSlotAccToKM] = useState({});

  // useEffect(() => {
  //   const fetchTimeSlot = async () => {
  //     //props.setLoading(true)
  //     try {
  //       const data = await DeliveryAndTimeSlot.GetDeliveryAndTimeSlot();
  //       // console.log(data);
  //       if (data) {
  //         setTimeslot(data);
  //         props.setLoading(false)
  //       } else {
  //         props.setLoading(false)
  //         alert("No data found");
  //       }
  //     } catch (error) {
  //       // console.error("Error fetching time slots:", error);
  //       // Handle the error appropriately, e.g., show an error message to the user
  //     }
  //   };

  //   fetchTimeSlot();
  // }, []); // Make sure to provide an empty dependency array to run the effect only once when the component mounts



  function convertTo12HourFormat(twentyFourHourTime) {
    const [hours, minutes] = twentyFourHourTime.split(':').map(Number);

    let period = 'AM';
    let twelveHourHours = hours;

    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        twelveHourHours -= 12;
      }
    }

    if (twelveHourHours === 0) {
      twelveHourHours = 12;
    }
    if (minutes != undefined && minutes != null) {
      const formattedMinutes = minutes === 0 ? '00' : minutes.toString().padStart(2, '0');
      return `${twelveHourHours.toString().padStart(2, '0')}:${formattedMinutes} ${period}`;
    }
    else {
      return `${twelveHourHours.toString().padStart(2, '0')}: 00 ${period}`;
    }
  }


  useEffect(() => {
    const fetchTime = () => {
      props.setLoading(true)
      // Function to find the array element based on delivery distance
      function findArrayElement(variable) {
        for (const element of TimeSlot) {
          if (
            variable >= element.delvryDistRangeFromKM &&
            variable <= element.delvryDistRangeToKM
          ) {
            return element;
          }
        }
        return null;
      }

      const result = findArrayElement(Distance);
      if (result) {
        props.setLoading(false)

        setTimeSlotAccToKM(result.deliveryTimeSlots);
      } else {
        props.setLoading(false)

        // console.log("No time slot found for the given distance");
      }
    };

    fetchTime(); // Call fetchTime inside the second useEffect
  }, [Distance, TimeSlot]); // Include Distance and TimeSlot in the dependency array

  // Assuming you have a state variable like this
  const [miscellaneousData, setMiscellaneousData] = useState([]);

  // useEffect(() => {
  //   const fetchMiscellaneousData = async (id) => {
  //     try {
  //       props.setLoading(true)
  //       const miscellaneousTime =
  //         await MiscellaneousMaster.GetMiscellaneousMasterById(id, 170);
  //       // console.log("MiscellaneousTime", miscellaneousTime);
  //       return miscellaneousTime;
  //     } catch (error) {
  //       props.setLoading(false)
  //       console.error("Error fetching miscellaneous data:", error);
  //       return null;
  //     }
  //   };

  //   // Check if TimeSlotAccToKM is an array before using forEach
  //   if (Array.isArray(TimeSlotAccToKM)) {
  //     // Initialize an empty array to store responses
  //     const responses = [];

  //     // Use Promise.all to wait for all API calls to complete
  //     Promise.all(
  //       TimeSlotAccToKM.map(async (id) => {
  //         const response = await fetchMiscellaneousData(id);
  //         responses.push(response);
  //       })
  //     ).then(() => {
  //       // Now responses array contains all the API responses
  //       setMiscellaneousData(responses);
  //       props.setLoading(false)

  //     });
  //   } else {
  //     console.error("TimeSlotAccToKM is not an array");
  //   }
  // }, [TimeSlotAccToKM]);


  const dataForAllotedTime = {
    deliveryPointName: 1,
    orderType: selectedService,
    distance: 0
  }

  useEffect(() => {
    const fetchdata = async () => {
      props.setLoading(true)
      try {
        const deliverySlot = await DeliveryAndTimeSlot.getAllottedDeliveryTimeSlots(dataForAllotedTime, CompanyDetail.isEnableGoogleMap);

        const data = deliverySlot.filter((data, index, self) =>
          index === self.findIndex((t) => (
            t.id === data.id
          ))
        );
        const serviceTypeId = dataForAllotedTime.orderType;

        const serviceData = props.serviceType.filter(item => item.id == serviceTypeId)
        const selectedTimeSlot = data.slice(0, serviceData[0]?.noOfTimeSlot);

        // alert(`Main- ${serviceData[0]?.noOfTimeSlot}`);

        if (data) {
          setMiscellaneousData(selectedTimeSlot);
          props.setLoading(false)
        } else {
          props.setLoading(false)
          alert("No data found in alloted");
        }
      } catch (error) {
        console.error("Error fetching time slots:", error);
        // Handle the error appropriately, e.g., show an error message to the user
      }
    }

    fetchdata()

  }, [selectedService])

  const convertDateFormat = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  };

  const handleDeliveryTimeChange = (e) => {
    props.setDeliveryTime(e.target.value);
    localStorage.setItem("deliveryTime", e.target.value)
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div className="main-modal-section-2">
        <div style={{ marginBottom: "8px" }} className="">
          {props.heading1}
        </div>
        <div className="select-k1" style={{ width: "100%" }}>
          <select name="" id="">
            <option value="">Mother's Hut</option>
            {/* <option value="">Other's</option> */}
          </select>
        </div>
      </div>
      <div className="main-modal-section-3">
        <div className=""> {props.heading2} </div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div className="select-k1" style={{ width: "50%" }}>
            <label htmlFor="">Delivery Date</label>
            <select name="" value={props.deliveryDate} id="" onChange={props.ChangeDeliveryDate}>
              {next5Days.map((date, index) => (
                <option key={index} value={convertDateFormat(date)}>
                  {date}
                </option>
              ))}
            </select>
          </div>
          <div className="select-k1" style={{ width: "50%" }}>
            <label htmlFor="">Delivery Time</label>
            <select name="" id="" value={props.deliveryTime}
              onChange={handleDeliveryTimeChange}>

              {(() => {
                const AllottedTimeSlot1 = props.AllottedTimeSlot
                if (
                  AllottedTimeSlot1 &&
                  Array.isArray(AllottedTimeSlot1) &&
                  AllottedTimeSlot1.length > 0
                ) {
                  return AllottedTimeSlot1.map((data) => (
                    <option key={data.id} value={data.id}>
                      {convertTo12HourFormat(data.availableFrom)} to{" "} {convertTo12HourFormat(data.availableTo)}
                    </option>
                  ));
                } else {
                  return (
                    <option value="">Not available</option>
                  );
                }
              })()}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainModalComponent;
