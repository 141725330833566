import axios from "../axiosConfig";

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const WebTemplate = {

    CreateCustomizedPageTemplate: async (data) => {
        try {
            const apiUrl = `${WebApiUrl}/api/CustomizedPageTemplate/CreateCustomizedPageTemplate`;
            console.log(apiUrl);
            data.textContents  =   encodeURIComponent(data.textContents)
            console.log(data);
            const response = await axios.post(apiUrl, {
                data
            }, {
                withCredentials: true,
                credentials: 'include',
            });

            console.log("Response:", response.data);
            return true;
        } catch (error) {
            console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },
    // ************************* Get All ***************************
    GetCustomizedPageTemplates: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/CustomizedPageTemplate/GetCustomizedPageTemplates`;
            const response = await axios.post(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

        // ************************* Get All ***************************
        GetCustomizedPageTemplatesWithFilter: async (filterData) => {
          const filteredData = filterData.map(data => ({
            fieldName: data.fieldName,
            operatorName: data.operatorName,
            compareValue: data.compareValue
        }));
          try {
              const apiUrl = `${WebApiUrl}/api/CustomizedPageTemplate/GetCustomizedPageTemplatesWithFilter`;
              const response = await axios.post(apiUrl,  {
                filter: filteredData,
                order: [
                    {
                        propertyName: "string",
                        ascending: true
                    }
                ],
                pageNumber: 0,
                pageSize: 50000
            }, {
                  withCredentials: true,
              });
              console.log('Response:', response.data.result[0]);
              return response.data.result[0];
  
          } catch (error) {
              console.error('Error:', error.message);
          }
      },

    // ************************* Get All ***************************
    getTableFilter: async () => {
        try {
            const apiUrl = `${WebApiUrl}/api/Table/GetTables`;
            const response = await axios.post(apiUrl, {
                filter: [],
                order: [
                    {
                        propertyName: "id",
                        ascending: false
                    }
                ],
                pageNumber: 0,
                pageSize: 500000
            }, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    // ************************* Delete ****************************
    deleteTable: async (tableId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/Table?tableId=${tableId}`;
            const response = await axios.delete(apiUrl, {
                withCredentials: true,
            });
            console.log('Delete Response:', response.data.result);
            return true;
        } catch (error) {
            console.error('Error:', error.message);
        }
    },

  // ************************* Get By Id ****************************
  GetCustomizedPageTemplate: async (Templateid) => {
    try {
      const apiUrl = `${WebApiUrl}/api/CustomizedPageTemplate/GetCustomizedPageTemplate?Templateid=${Templateid}`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  // *************************** Update ***************************
  UpdateCustomizedPageTemplate: async (TemplateData) => {
    // TemplateData.textContents  =   TemplateData.textContents
    // TemplateData.jsonStructure  =   JSON.stringify(TemplateData.jsonStructure)

    TemplateData.jsonStructure = encodeURIComponent(TemplateData.jsonStructure);
    TemplateData.textContents = encodeURIComponent(TemplateData.textContents);
    console.log(TemplateData);
    try {
      const apiUrl = `${WebApiUrl}/api/CustomizedPageTemplate/UpdateCustomizedPageTemplate?Templateid=${TemplateData.id}`;
      console.log(apiUrl);
      const response = await axios.put(
        apiUrl,
        {
          TemplateData,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );

      console.log("Response:", response.data);
      return response.data.isSuccess;
    } catch (error) {
      console.error("Error:", error.message);

            if (error.response && error.response.status === 401) {
                alert("Failed");
            } else {
                alert("An error occurred. Please try again.");
            }
        }
    },



    

    UpdateCustomizedPageTemplateImage: async (formData , Templateid) => {
        console.log(formData)
        try {
          // Assuming 'data' is a FormData object with appended fields
          const apiUrl = `${WebApiUrl}/api/CustomizedPageTemplate/UpdateCustomizedPageTemplateImage?CustomizedPageTemplateId=${Templateid}`;
    
          const response = await axios.post(apiUrl,
            formData, {
            withCredentials: true,
            headers: {
              'Content-Type': 'multipart/form-data',  // Set Content-Type for FormData
            },
          });
    
          console.log("Response:", response.data.isSuccess);
          return response.data.isSuccess;
        } catch (error) {
          console.error("Error:", error.message);
        }
      },
    

      CustomizedPageTemplateImageDetailWithTemplateId: async (CustomizedPageTemplateId) => {
        try {
            const apiUrl = `${WebApiUrl}/api/CustomizedPageTemplate/CustomizedPageTemplateImageDetail?CustomizedPageTemplateId=${CustomizedPageTemplateId}`;
            const response = await axios.get(apiUrl, {}, {
                withCredentials: true,
            });
            console.log('Response:', response.data.result);
            return response.data.result;

        } catch (error) {
            console.error('Error:', error.message);
        }
    },
};

export default WebTemplate;
