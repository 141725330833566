import React, { useContext, useEffect, useState } from "react";
import MainModalComponent from "./MainModalComponent";
import MainModalComponent2 from "./MainModalComponent2";
import order from "../API/API_SERVISES/Order";
import Loader from "./Loader";
import MainModalComponent3 from "./MainModalComponent3";
import DeliveryAndTimeSlot from "../API/API_SERVISES/DeliveryAndTimeSlot";
import MyContext from "./MyContext";
// import { fi } from "date-fns/locale";
import CurrentDateAndTime from "../API/API_SERVISES/CurrentDateAndTime";
import AddAddress from "../API/API_SERVISES/AddAddress";

const MainModal = (props) => {
  const [serviceType, setServiceType] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [selectedService, setSelectedService] = useState();

  const { CompanyDetail, selectedService, setSelectedService, setOrderType, AllottedTimeSlot, setAllottedTimeSlot, setAddAddresses } = useContext(MyContext);

  const [getDeliveryAndTimeSlotId, setDeliveryAndTimeSlotId] = useState({
    deliveryPointName: 1,
    orderType: 752,
    distance: 0,
    // distanceFrom: 0,
    isCurrentDate: true,
  });

  const [deliveryDate, setDeliveryDate] = useState();
  const [deliveryTime, setDeliveryTime] = useState();

  // useEffect(() => {
  //   // console.log("Updated deliveryAndTimeSlotId:", getDeliveryAndTimeSlotId);
  // }, [getDeliveryAndTimeSlotId]);

  useEffect(() => {
    const fetchdata = async () => {
      const data = await order.getServiceType();
      setServiceType(data);
      setLoading(false)
      if (data && data.length > 0 && selectedService === undefined) {
        setSelectedService(`${data[0].id}`);
        localStorage.setItem("ServiceType", `${data[0].id}`);
      }
      else if (selectedService === 752) {
        setSelectedService(752);
        localStorage.setItem("ServiceType", 752);
        // alert("else")
      }
      else {
        var isFromOrderTaker = localStorage.getItem("OrderTaker");
        if (isFromOrderTaker == "Yes") {
          setSelectedService(751);
          localStorage.setItem("ServiceType", 751);
        }
        else {
          setSelectedService(selectedService);
          localStorage.setItem("ServiceType", selectedService);
        }
      }

      let timeDistance = 0;
      if (selectedService == 752) {
        var loginDataString = localStorage.getItem("accountLogin");
        var loginData = JSON.parse(loginDataString);

        if (loginData) {
          const defaultData = await AddAddress.getAddress(loginData.d1Id)
          if (defaultData) {
            const defaultAddress = defaultData.filter(item => item.isDefault == true);
            //alert(defaultAddress[0].distance)
            if (defaultAddress[0]?.distance != null && defaultAddress[0]?.distance != 0) {
              timeDistance = defaultAddress[0].distance;
            }
          }
        }
        else {
          const googleData = localStorage.getItem("googleMapData");
          const distance = JSON.parse(googleData);

          const totaldistance = distance && distance?.rows[0]?.elements[0]?.distance?.text;
          if (totaldistance) {
            const spiltData = totaldistance.split(" ");
            timeDistance = spiltData[0];
          }
        }
      }

      const updatedValues = {
        distance: timeDistance,
        orderType: selectedService,
        deliveryPointName: 1,
        isCurrentDate: true
      };
      getDeliveryTimeSlots(updatedValues, data)
    };
    fetchdata();
  }, []);


  const handleServiceChangeModal = async (event) => {
    const newServiceId = Number(event.target.id);
    // alert(typeof (newServiceId))
    setSelectedService(newServiceId);
    localStorage.setItem("ServiceType", newServiceId);
    if (newServiceId == 752) {
      setOrderType("Home delivery")
    } else if (newServiceId == 753) {
      setOrderType("Pick Up")
    } else if (newServiceId == 751) {
      setOrderType("Table Order")
    } else if (newServiceId == undefined) {
      setOrderType("")
      // setSelectedService()
    }
    let timeDistance = 0;

    if (newServiceId == 752) {
      var loginDataString = localStorage.getItem("accountLogin");
      var loginData = JSON.parse(loginDataString);

      if (loginData) {
        const defaultData = await AddAddress.getAddress(loginData.d1Id)
        if (defaultData) {
          const defaultAddress = defaultData.filter(item => item.isDefault == true);
          //console.log(defaultAddress[0].distance)
          if (defaultAddress) {
            if (defaultAddress[0]?.distance && defaultAddress[0]?.distance != null && defaultAddress[0]?.distance != 0) {
              timeDistance = defaultAddress[0].distance;
            }
          }
        }
      }
      else {
        const googleData = localStorage.getItem("googleMapData");
        const distance = JSON.parse(googleData);

        const totaldistance = distance && distance?.rows[0]?.elements[0]?.distance?.text;
        if (totaldistance) {
          const spiltData = totaldistance.split(" ");
          timeDistance = spiltData[0];
        }
      }
    }

    if (CompanyDetail.isEnableGoogleMap) {
      if (newServiceId == 752) {
        if (timeDistance == 0 || timeDistance == null || timeDistance == undefined) {
          alert("The distance is 0. Please select an address from Google Maps");
        }
      }
    }

    setDeliveryAndTimeSlotId((prevState) => ({
      ...prevState,
      distance: timeDistance,
      orderType: newServiceId,
      deliveryPointName: 1,
      isCurrentDate: true
    }));

    const updatedValues = {
      ...getDeliveryAndTimeSlotId,
      distance: timeDistance,
      orderType: newServiceId,
      deliveryPointName: 1,
      isCurrentDate: true
    };
    await getDeliveryTimeSlots(updatedValues);

    const currentDate = await CurrentDateAndTime.getCurrentDate();
    // const date = localStorage.getItem("deliverydate");
    // if (date) {
    //   localStorage.setItem("deliverydate", date)
    // }
    // else {
    //   localStorage.setItem("deliverydate", currentDate)
    // }
    //console.log(getDeliveryAndTimeSlotId);
  };
  const getDeliveryTimeSlots = async (updatedValues, serviceTypeData) => {
    try {
      setLoading(true);
      const deliverySlot = await DeliveryAndTimeSlot.getAllottedDeliveryTimeSlots(updatedValues, CompanyDetail.isEnableGoogleMap);
      if (deliverySlot.length == 0) {
        setAllottedTimeSlot([])
        return false;
      }
      const uniqueSlots = deliverySlot.filter((data, index, self) =>
        index === self.findIndex((t) => t.id === data.id)
      );

      const serviceTypeId = updatedValues.orderType;

      const serviceData = (serviceType != null && serviceType != undefined && serviceType.length > 0)
        ? serviceType.filter(item => item.id == serviceTypeId)
        : serviceTypeData.filter(item => item.id == serviceTypeId)

      const selectedTimeSlot = uniqueSlots.slice(0, serviceData[0].noOfTimeSlot);

      setAllottedTimeSlot(selectedTimeSlot);
      localStorage.setItem("deliveryTime", selectedTimeSlot[0].id)
      setDeliveryTime(selectedTimeSlot[0]?.id)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching time slots:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  const fetchAddress = async () => {
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    if (loginData) {
      var addresses = await AddAddress.getAddress(loginData.d1Id);
      // console.log(addresses);
      if (addresses) {
        setAddAddresses(addresses)
      } else {
        alert("Error Occoured");
      }
    }
  }

  const getServiceTypeId = () => {
    localStorage.setItem("ServiceType", selectedService);
    fetchAddress();
    props.closemainmodal();
    props.fetchData()
  };
  // console.log(selectedService)

  const [isFromOrderTkr, setIsFromOrderTkr] = useState();

  useEffect(() => {
    var isFromOrderTak = localStorage.getItem("OrderTaker");
    setIsFromOrderTkr(isFromOrderTak);
  }, [])

  const getCurrentDate = async () => {
    // const date = new Date();
    // const year = date.getFullYear();
    // let month = date.getMonth() + 1;
    // let day = date.getDate();

    // if (month < 10) {
    //   month = `0${month}`;
    // }
    // if (day < 10) {
    //   day = `0${day}`;
    // }
    // const formattedDate = `${year}-${month}-${day}`;
    const date = await CurrentDateAndTime.getCurrentDate()
    return date;
  };

  const convertToIsoFormat = (dateStr) => {
    const [day, month, year] = dateStr.split('-');
    return `${year}-${month}-${day}`;
  };

  const ChangeDeliveryDate = async (e) => {

    const selectedIsoDate = e.target.value;
    localStorage.setItem("deliverydate", selectedIsoDate)
    setDeliveryDate(selectedIsoDate)

    const currentDate = await getCurrentDate();
    // alert(currentDate == selectedIsoDate)
    // alert(currentDate == convertToIsoFormat(selectedIsoDate))

    // ****************** fetch time slot **************************
    var isCrntDate;
    if (currentDate == selectedIsoDate)//convertToIsoFormat())
      isCrntDate = true
    else
      isCrntDate = false

    let timeDistance = 0;

    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);

    if (loginData) {
      const defaultData = await AddAddress.getAddress(loginData.d1Id)
      if (defaultData) {
        const defaultAddress = defaultData.filter(item => item.isDefault == true);
        //alert(defaultAddress[0].distance)
        if (defaultAddress[0].distance != null && defaultAddress[0].distance != 0) {
          timeDistance = defaultAddress[0].distance;
        }
      }
    }
    else {
      const googleData = localStorage.getItem("googleMapData");
      const distance = JSON.parse(googleData);

      const totaldistance = distance && distance?.rows[0]?.elements[0]?.distance?.text;
      if (totaldistance) {
        const spiltData = totaldistance.split(" ");
        timeDistance = spiltData[0];
      }
    }
    let updatedValues = {
      ...getDeliveryAndTimeSlotId,
      distance: timeDistance,
      orderType: selectedService,
      isCurrentDate: isCrntDate,
    };
    setDeliveryAndTimeSlotId((prevState) => ({
      ...prevState,
      distance: timeDistance,
      orderType: selectedService,
      isCurrentDate: isCrntDate,
    }));
    getDeliveryTimeSlots(updatedValues);
  };

  useEffect(() => {
    const fetchData = async () => {
      const currentDate = await CurrentDateAndTime.getCurrentDate()
      const date = localStorage.getItem("deliverydate");
      if (date) {
        localStorage.setItem("deliverydate", date)
        setDeliveryDate(date)
      }
      else {
        localStorage.setItem("deliverydate", currentDate)
        setDeliveryDate(currentDate)
      }
      const deliveryTime = localStorage.getItem("deliveryTime")
      if (deliveryTime) {
        localStorage.setItem("deliveryTime", deliveryTime)
        setDeliveryTime(deliveryTime);
      }
      else {
        localStorage.setItem("deliveryTime", AllottedTimeSlot[0]?.id)
        setDeliveryTime(AllottedTimeSlot[0]?.id);
      }
    }
    fetchData()
  }, [])


  

  const closeModal = () => {
    fetchAddress();
    props.closemainmodal();
    props.fetchData()
  }




  return (
    <div className="modal-back">
      <div className="main-modal-mothers-hut">
        <div className="xmark-ms-2" onClick={closeModal}>
          <i className="fa-solid fa-xmark"></i>
        </div>
        <i
          onClick={closeModal}
          style={{ color: "white", cursor: "pointer" }}
          className="fa-solid fa-xmark card-menu-favorite dis-non"
        ></i>
        <div className="main-modal-section-1">
          <div className="">Service Type</div>
          <div className="section-1-flex">
            {serviceType &&
              Array.isArray(serviceType) &&
              serviceType.length > 0 ? (
              serviceType
                .filter(type => {
                  return (
                    // (type.name !== "Home Delivery" || CompanyDetail.isEnableHomeDelivery) &&
                    // (type.name !== "Pick up" || CompanyDetail.isEnablePickUp) &&
                    // (type.name !== "Table Order" || CompanyDetail.isAllowTblOptionForGuest) &&
                    type.name !== "POS"
                  );
                })
                .map(type => {
                  // console.log(`testt- ${selectedService} and ${type.id}`, selectedService == `${type.id}`)
                  //if (type.id === 751) {
                  //if (CompanyDetail.isAllowTblOptionForGuest || isFromOrderTkr === "Yes") {
                  // return (
                  //   <div
                  //     key={type.id}
                  //     onClick={() => handleServiceChangeModal({ target: { id: `${type.id}` } })}
                  //   >
                  //     <input
                  //       type="radio"
                  //       name="serviceType"
                  //       id={`${type.id}`}
                  //       checked={selectedService == `${type.id}`}
                  //       onChange={handleServiceChangeModal}
                  //     />
                  //     {type.name}
                  //   </div>
                  // );
                  // }
                  // return null;
                  //}
                  return (
                    <div
                      key={type.id}
                      onClick={() => handleServiceChangeModal({ target: { id: `${type.id}` } })}
                      style={{ color: selectedService == `${type.id}` ? "rgb(221 221 221)" : "rgba(156, 163, 175, 1)" }}
                    >
                      {/* <input
                        type="checkbox"
                        name="serviceType"
                        id={`${type.id}`}
                        value={`${type.id}`}
                        checked={selectedService == `${type.id}`} // Ensure strict comparison
                        onChange={(e) => handleServiceChangeModal(e)} // Pass event to the handler
                      /> */}

                      <label class="container">
                        <input type="checkbox"
                          name="serviceType"
                          style={{ margin: "0" }}
                          id={`${type.id}`}
                          value={`${type.id}`}
                          checked={selectedService == `${type.id}`} // Ensure strict comparison
                          onChange={(e) => handleServiceChangeModal(e)} // Pass event to the handler
                        />
                        <div class="checkmark-1"></div>
                        <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg" class="celebrate">
                          <polygon points="0,0 10,10"></polygon>
                          <polygon points="0,25 10,25"></polygon>
                          <polygon points="0,50 10,40"></polygon>
                          <polygon points="50,0 40,10"></polygon>
                          <polygon points="50,25 40,25"></polygon>
                          <polygon points="50,50 40,40"></polygon>
                        </svg>
                      </label>
                      {type.name} <br />
                      {/* {type.id}--{selectedService} <br /> */}
                      {/* {selectedService === `${type.id}` ? "true" : "false"} */}
                    </div>
                  );
                })
            ) : null
            }
          </div>
        </div>

        {serviceType && serviceType.length > 0 &&
          selectedService == serviceType[1]?.id && (
            <MainModalComponent2
              heading1={"Delivery address"}
              heading2={"Delivered On"}
              loading={loading}
              setLoading={setLoading}
              AllottedTimeSlot={AllottedTimeSlot}
              ChangeDeliveryDate={ChangeDeliveryDate}
              serviceType={serviceType}
              getDeliveryAndTimeSlotId={getDeliveryAndTimeSlotId}
              getDeliveryTimeSlots={getDeliveryTimeSlots}
              deliveryDate={deliveryDate}
              deliveryTime={deliveryTime}
              setDeliveryTime={setDeliveryTime}
            />
          )}

        {serviceType && serviceType.length > 1 &&
          selectedService == serviceType[2]?.id && (
            <MainModalComponent3
              heading1={"Pickup Location"}
              heading2={"Pick-up On"}
              loading={loading}
              setLoading={setLoading}
              AllottedTimeSlot={AllottedTimeSlot}
              ChangeDeliveryDate={ChangeDeliveryDate}
              serviceType={serviceType}
              deliveryDate={deliveryDate}
              deliveryTime={deliveryTime}
              setDeliveryTime={setDeliveryTime}
            />
          )}

        {serviceType && serviceType.length > 2 &&
          selectedService == serviceType[0]?.id && (
            <MainModalComponent
              heading1={" Table Order Location"}
              heading2={"Book Table On"}
              loading={loading}
              setLoading={setLoading}
              AllottedTimeSlot={AllottedTimeSlot}
              ChangeDeliveryDate={ChangeDeliveryDate}
              serviceType={serviceType}
              deliveryDate={deliveryDate}
              deliveryTime={deliveryTime}
              setDeliveryTime={setDeliveryTime}
            />
          )}
        <button onClick={getServiceTypeId} className="main-modal-button-1">
          DONE
        </button>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default MainModal;

