import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import Payment from '../API/API_SERVISES/Payment';
import Order from '../API/API_SERVISES/Order';
import Loader from '../Component/Loader';
import CurrentDateAndTime from '../API/API_SERVISES/CurrentDateAndTime';

const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Response = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const handleBackClick = () => {
        // window.history.back();
        const Ord1Id = localStorage.getItem("Ord1Id")
        navigate("/TrackOrderStatus", { state: { orderNo: 0, ord1Id: Ord1Id } });//
        window.location.reload();
    }

    const [amount, setAmount] = useState(0)
    const [orderNo, setOrderNo] = useState(0)
    const [message, setMessage] = useState("Something went wrong")
    const [status, setStatus] = useState("")
    const { orderId } = useParams();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {

            // const queryParams = new URLSearchParams(location.search);

            // // Get the value of the query parameter 'orderId'
            // const id = queryParams.get('orderId');
            // console.log(id)
            // console.log(orderId)
            // debugger;

            // const currentUrl = window.location.href;
            // const orderNo = currentUrl.split(":")[1];
            try {
                setLoading(true);
                const orderNo = localStorage.getItem("JusPayOrderNo")
                const ord1Id = localStorage.getItem("Ord1Id")
                const apiUrl = `${WebApiUrl}/api/JusPayPayment/handleJuspayResponse`;
                const response = await axios.post(apiUrl, { orderNo: orderNo }, {
                    withCredentials: true,
                });
                console.log(response);
                setAmount(response.data.amount)
                setOrderNo(response.data.order_id)

                let message = ''
                let status = response.data.status

                switch (response.data.status) {
                    case "CHARGED":
                        message = "Thankyou for your Order"
                        status = "Payment Successful !!"
                        break
                    case "PENDING":
                    case "PENDING_VBV":
                        message = "Order Payment Pending"
                        status = "Payment Failed"
                        break
                    case "AUTHORIZATION_FAILED":
                        message = "Order Payment Authorization Failed"
                        status = "Payment Failed"
                        break
                    case "AUTHENTICATION_FAILED":
                        message = "Order Payment Authentication Failed"
                        status = "Payment Failed"
                        break
                    default:
                        message = "Your Payment is Pending"//"Order Status " + response.data.status
                        status = "Pending Payment" //response.data.status
                        break
                }
                setMessage(message);
                setStatus(status);
                // if (response.data.status == "CHARGED") {
                const filteredData = [{
                    fieldName: "ord1Id",
                    operatorName: "Equal",
                    compareValue: ord1Id,
                    // fieldName: "orderNo",
                    // operatorName: "Equal",
                    // compareValue: orderNo,
                }]

                const previousPaymentDetails = await Payment.getPaymentGatewayDetailsFilter(filteredData)

                const filterData = [{
                    fieldName: "ord1Id",
                    operatorName: "Equal",
                    compareValue: previousPaymentDetails[0].ord1Id,
                }]

                const orderData = await Order.getOrderReportFilter(filterData)
                const val = orderData[0].grandTotal - ((orderData[0].paymentAmount || 0) + (orderData[0].o1CancelledAmt || 0))

                const currentDate = new Date();
                const formattedDate = await CurrentDateAndTime.getCurrentDateTime();

                const storedArrayString = localStorage.getItem('accountLogin');
                const loginUser = JSON.parse(storedArrayString);

                if (response.data.status == "CHARGED") {
                    let updatedValues = [
                        // {
                        //     operationType: 0,
                        //     path: "PaymentModeId",
                        //     op: "Add",
                        //     from: "string",
                        //     value: paymentStatusValue
                        // },
                        {
                            operationType: 0,
                            path: "balanceAmt",
                            op: "Add",
                            from: "string",
                            value: 0
                        },
                        {
                            operationType: 0,
                            path: "paymentAmount",
                            op: "Add",
                            from: "string",
                            value: (orderData[0].paymentAmount || 0) + val
                        },
                        {
                            operationType: 0,
                            path: "paymentStatus",
                            op: "Add",
                            from: "string",
                            value: "Done"
                        },
                        {
                            operationType: 0,
                            path: "PaymentDoneOn",
                            op: "Add",
                            from: "string",
                            value: formattedDate
                        },
                        {
                            operationType: 0,
                            path: "PaymentDoneBy",
                            op: "Add",
                            from: "string",
                            value: loginUser.d1Id || loginUser.userId
                        },
                    ]
                    if (orderData && orderData[0].overallOrderStatus == "Pending") {
                        updatedValues.push({
                            operationType: 0,
                            path: "overallOrderStatus",
                            op: "Add",
                            from: "string",
                            value: "Confirm"
                        })
                    }
                    await Order.updateOrderPartially(updatedValues, previousPaymentDetails[0].ord1Id);

                    const paymentDetails = await Order.getPaymentModeByOrd1Id(previousPaymentDetails[0].ord1Id, "Online")

                    const paymentData = [{
                        ord1Id: previousPaymentDetails[0].ord1Id,
                        paymentModeId: ((paymentDetails && paymentDetails.length > 0) ? paymentDetails[0].paymentModeId : 0),
                        paymentAmount: val,
                        transId: response?.data?.payment_gateway_response?.epg_txn_id || "",
                        payUId: 0,
                        bankRefNo: response?.data?.payment_gateway_response?.rrn || "",
                        status: response?.data?.status || "",
                        paymentTypeMethod: response?.data?.payment_method_type || "",
                    }]
                    await Order.createPaymentModeWithOrder(paymentData, "0");
                }
                // ************************** Update Payment Partially ************
                const updatedData = [
                    // { operationType: 0, path: "apiId", op: "Add", from: "string", value: 0 },
                    // { operationType: 0, path: "apiName", op: "Add", from: "string", value: "" },
                    { operationType: 0, path: "pgOrderId", op: "Add", from: "string", value: response?.data?.id || "" },
                    { operationType: 0, path: "pgOrderStatus", op: "Add", from: "string", value: response?.data?.status || "" },
                    { operationType: 0, path: "pgTrackingId", op: "Add", from: "string", value: response?.data?.payment_gateway_response?.epg_txn_id || "" },
                    { operationType: 0, path: "pgBankRefNo", op: "Add", from: "string", value: response?.data?.payment_gateway_response?.rrn || "" },
                    { operationType: 0, path: "pgFailureMessage", op: "Add", from: "string", value: `Code-${response?.data?.bank_error_code || 0}, Status-${response?.data?.bank_error_message || 0}` },
                    { operationType: 0, path: "pgPaymentMode", op: "Add", from: "string", value: response?.data?.payment_method_type || "" },
                    { operationType: 0, path: "pgCardName", op: "Add", from: "string", value: response?.data?.card?.name_on_card || "" },
                    { operationType: 0, path: "pgStatusCode", op: "Add", from: "string", value: response?.data?.payment_gateway_response?.resp_code || "" },
                    { operationType: 0, path: "pgStatusMessage", op: "Add", from: "string", value: response?.data?.payment_gateway_response?.resp_message || "" },
                    { operationType: 0, path: "transactionDetail", op: "Add", from: "string", value: response?.data?.return_url || "" }, // return Url
                    { operationType: 0, path: "remarks", op: "Add", from: "string", value: "" },
                ]
                const data = await Payment.updatePaymentGatewayPartially(updatedData, previousPaymentDetails[0].id);
                // }
                setLoading(false);
            }
            catch (error) {
                console.log(error);
                setLoading(false)
            }
            //localStorage.setItem("JusPayOrderNo", 0);
        }
        fetchData();
    }, [])

    return (
        <>
            <div style={{ backgroundColor: "#001f3f" }}>
                <i class="fa-solid fa-arrow-left" onClick={handleBackClick} style={{ color: "white", fontSize: "20px", margin: "28px 0px 0px 28px" }}></i>
            </div>
            <div className="payment-success-container">
                <div className="success-message" style={{ marginTop: "-50px" }}>
                    <h1>{status}</h1>
                    <p>{message}</p>
                </div>
                <div className="order-details">
                    <p><b>Order Number:</b> {orderNo || 0}</p>
                    <p><b>Amount Paid:</b> ₹&nbsp;{amount || 0}</p>
                </div>
                <div style={{ marginTop: "45px" }}>
                    <button className="redBtn" style={{ width: "100%", backgroundColor: "rgb(15 231 241 / 33%)" }} onClick={handleBackClick}>Go to Home Page</button>
                </div>
            </div>
        </>
    );
};

export default Response;
