import axios from "../axiosConfig"; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const ItemMaster = {
  InsertItemMaster: async (data, restoAvailableTime1, commaSeparatedTags1) => {
    console.log(data);
    console.log(restoAvailableTime1)
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        // console.log("error", v);
        return false;
      }
    }
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster`;
      const response = await axios.post(
        apiUrl,
        {
          d1_Id: Number(data.D1_Id),
          d2_Id: Number(data.D2_Id),
          isFavorite: parseBoolean(data.IsFavorite),
          isActive: parseBoolean(data.IsActive),
          businessType: Number(data.BusinessType),
          productName: data.ProductName,
          productAlias: data.ProductAlias,
          printName: data.printName,
          parentGrp: Number(data.parentGrp),
          productUnit: Number(data.ProductUnit),
          productAltUnit: Number(data.ProductAltUnit),
          unitConvFactor: Number(data.UnitConvFactor),
          itemDepartment: Number(data.ItemDepartment),
          productType: Number(data.ProductType),
          sku: data.SKU,
          productCategory: Number(data.ProductCategory),
          enableBarcode: parseBoolean(data.EnableBarcode),
          defaultBarcode: data.DefaultBarcode,
          taxCategory1: Number(data.TaxCategory1),
          taxLimit: Number(data.TaxLimit),
          taxCategory2: Number(data.TaxCategory2),
          taxOnMRP: parseBoolean(data.TaxOnMRP),
          hSNCode: data.hsnCode,
          shortDescription: data.ShortDescription,
          longDescription: data.LongDescription,
          displayOrder: data.displayOrder,
          preparationTime: parseBoolean(data.PreparationTime),
          mealCourseType: parseBoolean(data.MealCourseType),
          restoAvailableTime: restoAvailableTime1,
          openingStock: data.OpeningStock,
          openingStock_barcodeGodown: [
            {
              godownId: 0,
              qty: 0,
              stockValue: 0,
              barcode: "",
              costPrice: 0,
              sellingPrice: 0,
            },
          ],
          openingStockValue: Number(data.OpeningStockValue),
          purchasePrice: Number(data.PurchasePrice),
          costPrice: Number(data.CostPrice),
          saleProfit: Number(data.SaleProfit),
          salePrice: Number(data.SalePrice),
          saleDiscount: Number(data.SaleDiscount),
          mrpPrice: Number(data.MrpPrice),
          purchaseDiscount: Number(data.PurchaseDiscount),
          minSalePrice: Number(data.MinSalePrice),
          branchPrice: Number(data.BranchPrice),
          franchisePrice: Number(data.FranchisePrice),
          normalLoss: Number(data.NormalLoss),
          ecomSalePrice: Number(data.EcomSalePrice),
          stockCheck: parseBoolean(data.StockCheck),
          virtualQuantity: Number(data.VirtualQuantity),
          availableQuantity: Number(data.AvailableQuantity),
          minQtyToPurchase: Number(data.MinQtyToPurchase),
          maxQtyToPurchase: Number(data.MaxQtyToPurchase),
          incrementQty: Number(data.IncrementQty),
          iDefaultGodown: Number(data.IDefaultGodown),
          iCateRateA: Number(data.ICateRateA),
          iCateRateB: Number(data.ICateRateB),
          iCateRateC: Number(data.ICateRateC),
          iCateRateD: Number(data.ICateRateD),
          iCateRateE: Number(data.ICateRateE),
          iCateRateF: Number(data.ICateRateF),
          iCateRateG: Number(data.ICateRateG),
          iCateRateH: Number(data.ICateRateH),
          iCateRateI: Number(data.ICateRateI),
          iCateRateJ: Number(data.ICateRateJ),
          loyaltyPoint: Number(data.LoyaltyPoint),
          referalAmountDirect: Number(data.ReferalAmountDirect),
          referalAmountIndirect: Number(data.ReferalAmountIndirect),
          salesManItemIncentive: Number(data.SalesManItemIncentive),
          sellerCommision: Number(data.SellerCommision),
          buyX: Number(data.BuyX),
          getX: Number(data.GetX),
          offerTagInCategory: data.OfferTagInCategory,
          offerTagInProduct: data.OfferTagInProduct,
          schemeType: Number(data.SchemeType),
          enableSerialNo: parseBoolean(data.EnableSerialNo),
          enableBatchNo: parseBoolean(data.EnableBatchNo),
          location: data.Location,
          rackNo: data.RackNo,
          enableStockReorder: parseBoolean(data.EnableStockReorder),
          maxStockLevelQty: Number(data.MaxStockLevelQty),
          minStockLevelQty: Number(data.MinStockLevelQty),
          reorderLevelQty: Number(data.ReorderLevelQty),
          enableLowStockWarning: parseBoolean(data.EnableLowStockWarning),
          isReturnable: parseBoolean(data.IsReturnable),
          isConsumable: parseBoolean(data.IsConsumable),
          isStockable: parseBoolean(data.IsStockable),
          allowRawMaterial: parseBoolean(data.AllowRawMaterial),
          defaultVendor: Number(data.DefaultVendor),
          lens_SPH: Number(data.Lens_SPH),
          lens_CYL: Number(data.Lens_CYL),
          lens_AXIS: Number(data.Lens_AXIS),
          lens_Dia: data.Lens_Dia,
          lens_Add: Number(data.Lens_Add),
          attributeList: [
            {
              itmId: 0,
              sn: 0,
              qty: 0,
              purchasePrice: 0,
              salePrice: 0,
              mrp: 0,
              attributes: [
                {
                  index: 0,
                  attrGrpId: 0,
                  attrId: 0,
                  attrName: "",
                },
              ],
            },
          ],
          lensAttributeList: [
            {
              barcode: 0,
              spH_frm: 0,
              spH_to: 0,
              spH_stp: 0,
              cyL_frm: 0,
              cyL_to: 0,
              cyL_stp: 0,
              axiS_frm: 0,
              axiS_to: 0,
              axiS_stp: 0,
              ad_frm: 0,
              ad_to: 0,
              ad_stp: 0,
              dia: 0,
            },
          ],
          isAttributeItem: parseBoolean(data.IsAttributeItem),
          iAttributeParent: Number(data.IAttributeParent),
          drugCompany: data.DrugCompany,
          drugMFDB: data.DrugMFDB,
          drugSaltName: Number(data.DrugSaltName),
          drugManufacturer: Number(data.DrugManufacturer),
          drugStorageType: data.DrugStorageType,
          drugTBItem: Number(data.DrugTBItem),
          drugSchedule_H: parseBoolean(data.DrugSchedule_H),
          drugSchedule_H1: parseBoolean(data.DrugSchedule_H1),
          drugSchedule_C: parseBoolean(data.DrugSchedule_C),
          drugSchedule_C1: parseBoolean(data.DrugSchedule_C1),
          drugSchedule_G: parseBoolean(data.DrugSchedule_G),
          drugAnti_TB: parseBoolean(data.DrugAnti_TB),
          drugNorcotics: parseBoolean(data.DrugNorcotics),

          batchDetails: [
            {
              godownId: 0,
              batchNo: "",
              mfgDate: "2024-02-10",
              expDate: "2024-02-10",
              qty: 0,
              purPrice: 0,
              salePrice: 0,
              mrp: 0,
              costPrice: 0,
              opPack: "",
              opLoose: "",
              opValue: "",
            },
          ],
          useInEcom: parseBoolean(data.UseInEcom),
          showOnHomePage: parseBoolean(data.ShowOnHomePage),
          disableBuyButton: parseBoolean(data.DisableBuyButton),
          disableWishListButton: parseBoolean(data.DisableWishListButton),
          visibleIndividually: parseBoolean(data.VisibleIndividually),
          allowCustomerReviews: parseBoolean(data.AllowCustomerReviews),
          allowCustomerRemarks: parseBoolean(data.AllowCustomerRemarks),
          published: parseBoolean(data.Published),
          addasComplementaryItem: parseBoolean(data.AddasComplementaryItem),
          availableForHomeDelivery: parseBoolean(data.AvailableForHomeDelivery),
          availableForPOS: parseBoolean(data.AvailableForPOS),
          itemAmtInComplementBonusAmt: parseBoolean(
            data.ItemAmtInComplementBonusAmt
          ),
          intermediate: parseBoolean(data.Intermediate),
          popularCategoryPriority: Number(data.PopularCategoryPriority),
          productTag: commaSeparatedTags1,
          specifyIndexNo: Number(data.SpecifyIndexNo),
          measurmentGuideTool: data.MeasurmentGuideTool,
          measurmentHeightImage: data.MeasurmentHeightImage,
          heightTitle: Number(data.HeightTitle),
          height: Number(data.Height),
          measurmentWidthImage: data.MeasurmentWidthImage,
          widthTilte: Number(data.WidthTilte),
          width: Number(data.Width),
          measurmentWeightImage: data.MeasurmentWeightImage,
          weightTitle: Number(data.WeightTitle),
          weight: Number(data.Weight),
          measurmentLengthImage: data.MeasurmentLengthImage,
          lengthTitle: Number(data.LengthTitle),
          length: Number(data.Length),
          enableShipping: parseBoolean(data.EnableShipping),
          cashOnDeliveryAvailable: parseBoolean(data.CashOnDeliveryAvailable),
          freeShipping: parseBoolean(data.FreeShipping),
          shipSeparately: parseBoolean(data.ShipSeparately),
          localShippingCharge: Number(data.LocalShippingCharge),
          zonalShippingCharge: Number(data.ZonalShippingCharge),
          nationalShippingCharge: Number(data.NationalShippingCharge),
          returnApplicationDays: Number(data.ReturnApplicationDays),
          returnPolicyNotes: data.ReturnPolicyNotes,
          tryAtHome: parseBoolean(data.TryAtHome),
          additionShippingCharge: Number(data.AdditionShippingCharge),
          ecomCategoryDetail: data.EcomCategoryDetail,
          //   [0]
          metaKeywords: data.MetaKeywords,
          metaDescription: data.MetaDescription,
          metaTitle: data.MetaTitle,
          searchEnginFriendlyPageName: data.SearchEnginFriendlyPageName,
          isDeleted: parseBoolean(data.IsDeleted),
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response);
      return response;
      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetItemMaster: async (index) => {
    // var index = 0
    try {

      const apiUrl = `${WebApiUrl}/api/ItemMaster/GetItemMasters`;
      const response = await axios.post(
        apiUrl,
        {
          filter: [],
          order: [
            {
              propertyName: "string",
              ascending: true
            }
          ],
          pageNumber: index,
          pageSize: 0
        },
        {
          withCredentials: true,
        }
      );
      // console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetItemMasterFilterWithPage: async (pageIndex) => {

    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster/GetItemMasters`;
      const response = await axios.post(
        apiUrl,
        {
          filter: [],
          order: [
            {
              propertyName: "d1Id",
              ascending: false
            }
          ],
          pageNumber: 0,
          pageSize: 5000
        },
        {
          withCredentials: true,
          credentials: 'include',
        }
      );
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  ItemMasterImageUpload: async (data) => {
    try {
  
      // Assuming 'data' is a FormData object with appended fields
      const apiUrl = `${WebApiUrl}/api/ItemMaster/SaveImage`;

      const response = await axios.post(apiUrl, data, {
        withCredentials: true,
        // headers: {
        //   "Content-Type": "multipart/form-data", // Set Content-Type for FormData
        // },
      });

      console.log("Response:", response.data.isSuccess);
      return response.data.isSuccess;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  GetItemMasterById: async (ItemMasterId) => {
    try {
    
      const apiUrl = `${WebApiUrl}/api/ItemMaster?ItemMasterId=${ItemMasterId}`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      //setEditCallBack(response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  deleteItemMaster: async (ItemMasterId) => {
    try {
    
      const apiUrl = `${WebApiUrl}/api/ItemMaster?ItemMasterId=${ItemMasterId}`;
      const response = await axios.delete(apiUrl, {
        withCredentials: true,
      });
      console.log("Delete Response:", response.data.result);
      return true;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  //   UpdateItemMasterImage: async (imageArray, id1, id2) => {
  //     console.log(imageArray)
  //     debugger
  //     try {
  //         // const formData = new FormData();

  //         // Append each image to the FormData
  //         // imageArray.forEach((image, index) => {
  //         //     formData.append(`images[${index}]`, image);
  //         // });

  //         const apiUrl = `${express_server_url}/api/ItemMaster/UpdateItemMasterImage`;
  //         const response = await axios.post(apiUrl, imageArray, {
  //             withCredentials: true,
  //             headers: {
  //                 'Content-Type': 'multipart/form-data',
  //             },
  //         });

  //         console.log('Response:', response.data.isSuccess);
  //         return response.data.isSuccess;
  //     } catch (error) {
  //         console.error('Error:', error.message);
  //         // You might want to throw the error or handle it based on your application's requirements
  //         throw error;
  //     }
  // },


  UpdateItemMasterImage: async (imageArray, id1, id2) => {
    try {
      console.log(imageArray);
      console.log(id1);
      console.log(id2);
      console.log(imageArray.length);

      const keys = Object.keys(imageArray);

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const file = imageArray[key];
        console.log(file);
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))
        const formData = new FormData();
        formData.append('files', file);
        formData.append('BranchId', id1);
        formData.append('D1ItemId', id2);

        const formDataToObject = (formData) => {
          const obj = {};
          formData.forEach((value, key) => {
            obj[key] = value;
          });
          return obj;
        };

        var formdatamain = formDataToObject(formData);
      
        const apiUrl = `${WebApiUrl}/api/ItemMaster/UpdateItemMasterImage`;
        const response = await axios.post(apiUrl, formdatamain, {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        console.log('Response:', response.data.isSuccess);
        if (!response.data.isSuccess) {
          // Handle the failure based on your application's requirements
          console.error('Update failed:', response.data.errorMessage);
        }

        await delay(1000);
      }

      // If you want to return something after all files are processed successfully, do it here
      return true;
    } catch (error) {
      console.error('Error:', error.message);
      // You might want to throw the error or handle it based on your application's requirements
      throw error;
    }
  },


  updateItemMaster: async (data, restoAvailableTime1, commaSeparatedTags1) => {
    function parseBoolean(v) {
      if (typeof v === "boolean") {
        return v;
      } else if (typeof v === "string") {
        v = v.toLowerCase().trim();
        return v === "true";
      } else {
        console.log("error", v);
        return false;
      }
    }
    console.log(data);
    try {
      
      const apiUrl = `${WebApiUrl}/api/ItemMaster?ItemMasterId=${data.id}`;
      const response = await axios.put(
        apiUrl,
        {
          d1_Id: Number(data.D1_Id),
          d2_Id: Number(data.D2_Id),
          isFavorite: parseBoolean(data.IsFavorite),
          isActive: parseBoolean(data.IsActive),
          businessType: Number(data.BusinessType),
          productName: data.ProductName,
          productAlias: data.ProductAlias,
          printName: data.printName,
          parentGrp: Number(data.parentGrp),
          productUnit: Number(data.ProductUnit),
          productAltUnit: Number(data.ProductAltUnit),
          unitConvFactor: Number(data.UnitConvFactor),
          itemDepartment: Number(data.ItemDepartment),
          productType: Number(data.ProductType),
          sku: data.SKU,
          productCategory: Number(data.ProductCategory),
          enableBarcode: parseBoolean(data.EnableBarcode),
          defaultBarcode: data.DefaultBarcode,
          taxCategory1: Number(data.TaxCategory1),
          taxLimit: Number(data.TaxLimit),
          taxCategory2: Number(data.TaxCategory2),
          taxOnMRP: parseBoolean(data.TaxOnMRP),
          hSNCode: data.hsnCode,
          shortDescription: data.ShortDescription,
          longDescription: data.LongDescription,
          displayOrder: data.displayOrder,
          preparationTime: parseBoolean(data.PreparationTime),
          mealCourseType: parseBoolean(data.MealCourseType),
          restoAvailableTime: restoAvailableTime1,
          openingStock: data.OpeningStock,
          openingStock_barcodeGodown: [
            {
              godownId: 0,
              qty: 0,
              stockValue: 0,
              barcode: "",
              costPrice: 0,
              sellingPrice: 0,
            },
          ],
          openingStockValue: Number(data.OpeningStockValue),
          purchasePrice: Number(data.PurchasePrice),
          costPrice: Number(data.CostPrice),
          saleProfit: Number(data.SaleProfit),
          salePrice: Number(data.SalePrice),
          saleDiscount: Number(data.SaleDiscount),
          mrpPrice: Number(data.MrpPrice),
          purchaseDiscount: Number(data.PurchaseDiscount),
          minSalePrice: Number(data.MinSalePrice),
          branchPrice: Number(data.BranchPrice),
          franchisePrice: Number(data.FranchisePrice),
          normalLoss: Number(data.NormalLoss),
          ecomSalePrice: Number(data.EcomSalePrice),
          stockCheck: parseBoolean(data.StockCheck),
          virtualQuantity: Number(data.VirtualQuantity),
          availableQuantity: Number(data.AvailableQuantity),
          minQtyToPurchase: Number(data.MinQtyToPurchase),
          maxQtyToPurchase: Number(data.MaxQtyToPurchase),
          incrementQty: Number(data.IncrementQty),
          iDefaultGodown: Number(data.IDefaultGodown),
          iCateRateA: Number(data.ICateRateA),
          iCateRateB: Number(data.ICateRateB),
          iCateRateC: Number(data.ICateRateC),
          iCateRateD: Number(data.ICateRateD),
          iCateRateE: Number(data.ICateRateE),
          iCateRateF: Number(data.ICateRateF),
          iCateRateG: Number(data.ICateRateG),
          iCateRateH: Number(data.ICateRateH),
          iCateRateI: Number(data.ICateRateI),
          iCateRateJ: Number(data.ICateRateJ),
          loyaltyPoint: Number(data.LoyaltyPoint),
          referalAmountDirect: Number(data.ReferalAmountDirect),
          referalAmountIndirect: Number(data.ReferalAmountIndirect),
          salesManItemIncentive: Number(data.SalesManItemIncentive),
          sellerCommision: Number(data.SellerCommision),
          buyX: Number(data.BuyX),
          getX: Number(data.GetX),
          offerTagInCategory: data.OfferTagInCategory,
          offerTagInProduct: data.OfferTagInProduct,
          schemeType: Number(data.SchemeType),
          enableSerialNo: parseBoolean(data.EnableSerialNo),
          enableBatchNo: parseBoolean(data.EnableBatchNo),
          location: data.Location,
          rackNo: data.RackNo,
          enableStockReorder: parseBoolean(data.EnableStockReorder),
          maxStockLevelQty: Number(data.MaxStockLevelQty),
          minStockLevelQty: Number(data.MinStockLevelQty),
          reorderLevelQty: Number(data.ReorderLevelQty),
          enableLowStockWarning: parseBoolean(data.EnableLowStockWarning),
          isReturnable: parseBoolean(data.IsReturnable),
          isConsumable: parseBoolean(data.IsConsumable),
          isStockable: parseBoolean(data.IsStockable),
          allowRawMaterial: parseBoolean(data.AllowRawMaterial),
          defaultVendor: Number(data.DefaultVendor),
          lens_SPH: Number(data.Lens_SPH),
          lens_CYL: Number(data.Lens_CYL),
          lens_AXIS: Number(data.Lens_AXIS),
          lens_Dia: data.Lens_Dia,
          lens_Add: Number(data.Lens_Add),
          attributeList: [
            {
              itmId: 0,
              sn: 0,
              qty: 0,
              purchasePrice: 0,
              salePrice: 0,
              mrp: 0,
              attributes: [
                {
                  index: 0,
                  attrGrpId: 0,
                  attrId: 0,
                  attrName: "",
                },
              ],
            },
          ],
          lensAttributeList: [
            {
              barcode: 0,
              spH_frm: 0,
              spH_to: 0,
              spH_stp: 0,
              cyL_frm: 0,
              cyL_to: 0,
              cyL_stp: 0,
              axiS_frm: 0,
              axiS_to: 0,
              axiS_stp: 0,
              ad_frm: 0,
              ad_to: 0,
              ad_stp: 0,
              dia: 0,
            },
          ],
          isAttributeItem: parseBoolean(data.IsAttributeItem),
          iAttributeParent: Number(data.IAttributeParent),
          drugCompany: data.DrugCompany,
          drugMFDB: data.DrugMFDB,
          drugSaltName: Number(data.DrugSaltName),
          drugManufacturer: Number(data.DrugManufacturer),
          drugStorageType: data.DrugStorageType,
          drugTBItem: Number(data.DrugTBItem),
          drugSchedule_H: parseBoolean(data.DrugSchedule_H),
          drugSchedule_H1: parseBoolean(data.DrugSchedule_H1),
          drugSchedule_C: parseBoolean(data.DrugSchedule_C),
          drugSchedule_C1: parseBoolean(data.DrugSchedule_C1),
          drugSchedule_G: parseBoolean(data.DrugSchedule_G),
          drugAnti_TB: parseBoolean(data.DrugAnti_TB),
          drugNorcotics: parseBoolean(data.DrugNorcotics),

          batchDetails: [
            {
              godownId: 0,
              batchNo: "",
              mfgDate: "2024-02-10",
              expDate: "2024-02-10",
              qty: 0,
              purPrice: 0,
              salePrice: 0,
              mrp: 0,
              costPrice: 0,
              opPack: "",
              opLoose: "",
              opValue: "",
            },
          ],
          useInEcom: parseBoolean(data.UseInEcom),
          showOnHomePage: parseBoolean(data.ShowOnHomePage),
          disableBuyButton: parseBoolean(data.DisableBuyButton),
          disableWishListButton: parseBoolean(data.DisableWishListButton),
          visibleIndividually: parseBoolean(data.VisibleIndividually),
          allowCustomerReviews: parseBoolean(data.AllowCustomerReviews),
          allowCustomerRemarks: parseBoolean(data.AllowCustomerRemarks),
          published: parseBoolean(data.Published),
          addasComplementaryItem: parseBoolean(data.AddasComplementaryItem),
          availableForHomeDelivery: parseBoolean(data.AvailableForHomeDelivery),
          availableForPOS: parseBoolean(data.AvailableForPOS),
          itemAmtInComplementBonusAmt: parseBoolean(
            data.ItemAmtInComplementBonusAmt
          ),
          intermediate: parseBoolean(data.Intermediate),
          popularCategoryPriority: Number(data.PopularCategoryPriority),
          productTag: commaSeparatedTags1,
          specifyIndexNo: Number(data.SpecifyIndexNo),
          measurmentGuideTool: data.MeasurmentGuideTool,
          measurmentHeightImage: data.MeasurmentHeightImage,
          heightTitle: Number(data.HeightTitle),
          height: Number(data.Height),
          measurmentWidthImage: data.MeasurmentWidthImage,
          widthTilte: Number(data.WidthTilte),
          width: Number(data.Width),
          measurmentWeightImage: data.MeasurmentWeightImage,
          weightTitle: Number(data.WeightTitle),
          weight: Number(data.Weight),
          measurmentLengthImage: data.MeasurmentLengthImage,
          lengthTitle: Number(data.LengthTitle),
          length: Number(data.Length),
          enableShipping: parseBoolean(data.EnableShipping),
          cashOnDeliveryAvailable: parseBoolean(data.CashOnDeliveryAvailable),
          freeShipping: parseBoolean(data.FreeShipping),
          shipSeparately: parseBoolean(data.ShipSeparately),
          localShippingCharge: Number(data.LocalShippingCharge),
          zonalShippingCharge: Number(data.ZonalShippingCharge),
          nationalShippingCharge: Number(data.NationalShippingCharge),
          returnApplicationDays: Number(data.ReturnApplicationDays),
          returnPolicyNotes: data.ReturnPolicyNotes,
          tryAtHome: parseBoolean(data.TryAtHome),
          additionShippingCharge: Number(data.AdditionShippingCharge),
          ecomCategoryDetail: data.EcomCategoryDetail,
          //   [0]
          metaKeywords: data.MetaKeywords,
          metaDescription: data.MetaDescription,
          metaTitle: data.MetaTitle,
          searchEnginFriendlyPageName: data.SearchEnginFriendlyPageName,
          isDeleted: parseBoolean(data.IsDeleted),
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data.isSuccess);
      return response.data.isSuccess;

      // setMainDataCallback(response.data.result);
    } catch (error) {
      console.error("Error:", error.message);
    }
  },

  ImageDetail: async (Branch_Id, Account_id) => {
    try {
      const apiUrl = `${WebApiUrl}/api/GetImageId?Account_id=${Account_id}?Branch_Id=${Branch_Id}`;
      const response = await axios.get(
        apiUrl,
        {},
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response.data.result);
      //setEditCallBack(response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  // ************************* Get Item Master Filter *********************
  GetItemMasterFilter: async (filterData) => {
    const filteredData = filterData.map(data => ({
      fieldName: data.fieldName,
      operatorName: data.operatorName,
      compareValue: data.compareValue
    }));
    try {
      const apiUrl = `${WebApiUrl}/api/ItemMaster/GetItemMasters`;
      const response = await axios.post(
        apiUrl, {
        filter: filteredData,
        order: [
          {
            propertyName: "string",
            ascending: true
          }
        ],
        pageNumber: 0,
        pageSize: 50000
      },
        {
          withCredentials: true,
        }
      );
      console.log("Response:", response);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },


};

export default ItemMaster;
